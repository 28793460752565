@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
.sections-with-pagination {
  margin-top: 120px;
  // position: relative;
  @include desktopStyle {
    margin-top: get-vw(120px);
  }
  @media only screen and (max-width: $bp-tablet) {
    margin-top: 80px;
    & > div {
      scroll-margin-top: 115px;
    }
  }
  @media only screen and (max-width: $bp-phone) {
    margin-top: 30px;
  }
}