@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
[data-color-scheme="blue"] {
  .block-action {
    color: var(--white);
  }
}

.block-action {
  margin: 40px 0;
  @include desktopStyle {
    margin: get-vw(40px) 0;
  }
  @media only screen and (max-width: $bp-tablet) {
    display: none;
  }

  &_wrapper {
    background: var(--bg-blue-5);
    border-radius: 12px;
    gap: 28px;
    grid-gap: 28px;
    padding: 32px 24px;
    overflow: hidden;
    @include desktopStyle {
      border-radius: get-vw(12px);
      gap: get-vw(28px);
      grid-gap: get-vw(28px);
      padding: get-vw(32px) get-vw(24px);
    }

    // default 'blue'
    $bg-colors:
      "green_dark" var(--bg-green) var(--bg-green-light-3),
      "blue_light" var(--bg-blue-light) var(--bg-blue-light-2),
      "yellow" var(--bg-yellow) var(--bg-yellow-light-3),
      "brown" var(--bg-brown) var(--bg-brown-light-3),
      "green" var(--bg-green-2) var(--bg-green-light-3),
      "green_light" var(--bg-green-light-2) var(--bg-green-light-3),
      "blue_sky" var(--bg-blue-sky) var(--bg-blue-light-2),
      "violet_light" var(--bg-violet-light)  var(--bg-violet-light-2),
      "brown_light" var(--bg-brown-light) var(--bg-brown-light-2),
      "pink" var(--bg-pink) var(--bg-pink),
      "pink_light" var(--bg-pink-light) var(--bg-pink-light),
      "turquoise" var(--bg-turquoise) var(--bg-turquoise-light);
    @each $name, $color, $labelColor in $bg-colors {
      &.bg-#{$name} {
        background: $color;
        .block-action_label-text {
          background: $labelColor;
        }
      }
    }
  }

  &_label-text {
    background: var(--bg-label-blue);
    text-transform: uppercase;
    border-radius: 76px;
    padding: 11px 25px;
    @include desktopStyle {
      border-radius: get-vw(76px);
      padding: get-vw(11px) get-vw(25px);
    }
  }

  &_content {
    width: 620px;
    @include desktopStyle {
      width: get-vw(620px);
    }
  }

  &_title {
    margin-bottom: 4px;
    @include desktopStyle {
      margin-bottom: get-vw(4px);
    }
  }

  &_icon {
    margin-top: -32px;
    margin-bottom: -32px;
    width: 438px;
    @include desktopStyle {
      margin-top: get-vw(-32px);
      margin-bottom: get-vw(-32px);
      width: get-vw(438px);
    }
    img {
      display: block;
      width: auto;
      height: auto;
      max-height: 100%;
    }
  }

  &_btn {
    white-space: nowrap;
    :global(.btn) {
      padding-left: 32px;
      padding-right: 24px;
      @include desktopStyle {
        padding-left: get-vw(32px);
        padding-right: get-vw(24px);
      }
    }
    svg {
      @include desktopStyle {
        width: get-vw(16px);
        height: get-vw(16px);
      }
    }
  }
}
