@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
[data-color-scheme="whiteBlack"] {
  .app-banner.bg-blue {
    .app-banner_left-footer-right-btns :global(.btn-app) {
      background: #fff;
      img {
        filter: grayscale(100%) invert(1);
      }
    }
  }
}

[data-color-scheme="blackWhite"] {
  .app-banner.bg-blue {
    color: var(--gray-dark-2);
    background: var(--bg-blue-light-2);
    .app-banner_left-body-text,
    .app-banner_left-footer-right-text {
      color: var(--gray-dark-2);
    }
  }
}

.app-banner {
  background: var(--blue-light);
  padding-top: 80px;
  @include desktopStyle {
    padding-top: get-vw(80px);
  }
  @media only screen and (max-width: $bp-tablet) {
    padding-top: 40px;
  }

  &:first-child {
    @media only screen and (min-width: $bp-tablet + 1) {
      padding-top: 60px;
      @include desktopStyle {
        padding-top: get-vw(80px);
      }
    }
  }

  &_inner {
    gap: 32px;
    grid-gap: 32px;
    @include desktopStyle {
      gap: get-vw(32px);
      grid-gap: get-vw(32px);
    }
    @media only screen and (max-width: $bp-tablet) {
      display: block;
    }
  }
  &_left {
    flex: 1;
    @media only screen and (max-width: $bp-tablet) {
      margin-bottom: 32px;
    }

    &-body {
      max-width: 670px;
      margin-bottom: 72px;
      @include desktopStyle {
        max-width: get-vw(670px);
        margin-bottom: get-vw(72px);
      }
      @media only screen and (max-width: $bp-tablet) {
        max-width: none;
        margin-bottom: 32px;
      }

      &-title {
        @media only screen and (max-width: $bp-tablet) {
          line-height: 1.25;
        }
      }
      &-text {
        line-height: 1.5;
        color: var(--gray);
        margin-bottom: 0;
        @media only screen and (max-width: $bp-tablet) {
          line-height: 1.3;
        }

        &:not(:first-child) {
          margin-top: 24px;
          @include desktopStyle {
            margin-top: get-vw(24px);
          }
          @media only screen and (max-width: $bp-phone) {
            margin-top: 8px;
          }
        }
      }
      &-link {
        &:not(:first-child) {
          margin-top: 32px;
          @include desktopStyle {
            margin-top: get-vw(32px);
          }
        }
      }
    }

    &-footer {
      max-width: 740px;
      gap: 24px;
      grid-gap: 24px;
      @include desktopStyle {
        max-width: get-vw(740px);
        gap: get-vw(24px);
        grid-gap: get-vw(24px);
      }
      @media only screen and (max-width: $bp-tablet) {
        display: block;
        max-width: none;
      }

      &-qr {
        width: 192px;
        flex-shrink: 0;
        @include desktopStyle {
          width: get-vw(192px);
        }
        @media only screen and (max-width: $bp-tablet) {
          display: none;
        }

        img {
          display: block;
          width: 100%;
          height: auto;
        }
      }
      &-right {
        flex: 1;
        display: flex;
        flex-direction: column-reverse;
        &-text {
          color: var(--gray);
          margin-top: 10px;
          margin-bottom: 0;
          @include desktopStyle {
            margin-top: get-vw(10px);
          }
          @media only screen and (max-width: $bp-tablet) {
            margin-top: 16px;
          }
        }
        &-btns {
          gap: 24px;
          grid-gap: 24px;
          @include desktopStyle {
            gap: get-vw(24px);
            grid-gap: get-vw(24px);
          }
          @media only screen and (max-width: $bp-tablet) {
            display: block;
          }

          :global(.btn-app) {
            flex: 1;
            &:not(:last-child) {
              @media only screen and (max-width: $bp-tablet) {
                margin-bottom: 8px;
              }
            }
          }
        }
      }
    }
  }
  &_right {
    flex: 0 1 783px;
    align-self: flex-end;
    @include desktopStyle {
      flex-basis: get-vw(783px);
    }

    &-wr {
      position: relative;
      @media only screen and (max-width: $bp-tablet) {
        max-width: 782px;
        margin: 0 auto;
      }
    }
    &-img {
      display: block;
      width: 100%;
      height: auto;
    }
    &-qr {
      display: none;
      position: absolute;
      left: 26px;
      bottom: 54px;
      width: 182px;
      height: auto;
      @include desktopStyle {
        left: get-vw(26px);
        bottom: get-vw(54px);
        width: get-vw(182px);
      }
      @media only screen and (max-width: $bp-tablet) {
        display: block;
        left: 0;
        bottom: 10px;
      }
      @media only screen and (max-width: $bp-phone) {
        width: 86px;
      }
    }
  }

  &.design-v2 {
    padding-bottom: 80px;
    @include desktopStyle {
      padding-bottom: get-vw(80px);
    }
    @media only screen and (max-width: $bp-tablet) {
      padding-bottom: 40px;
    }

    .app-banner_left {
      &-body {
        margin-bottom: 126px;
        @include desktopStyle {
          margin-bottom: get-vw(126px);
        }
        @media only screen and (max-width: $bp-tablet) {
          margin-bottom: 32px;
        }

        &-title {
          font-size: 5.6rem;
          @media only screen and (max-width: $bp-tablet) {
            font-size: 3.6rem;
          }
          @media only screen and (max-width: $bp-phone) {
            font-size: 3.2rem;
          }
        }
        &-text {
          max-width: 436px;
          line-height: 1.6;
          @include desktopStyle {
            max-width: get-vw(436px);
          }
          @media only screen and (max-width: $bp-tablet) {
            max-width: none;
          }
        }
      }
      &-footer {
        max-width: 581px;
        @include desktopStyle {
          max-width: get-vw(581px);
        }
        @media only screen and (max-width: $bp-tablet) {
          max-width: none;
        }

        &-right {
          display: block;
          &-text {
            color: var(--gray-dark);
            line-height: 1.5;
            margin-top: 0;
            margin-bottom: 16px;
            @include desktopStyle {
              margin-bottom: get-vw(16px);
            }
          }
          &-btns {
            display: flex;
            gap: 16px;
            grid-gap: 16px;
            @include desktopStyle {
              gap: get-vw(16px);
              grid-gap: get-vw(16px);
            }
            @media only screen and (max-width: $bp-tablet) {
              gap: 8px;
              grid-gap: 8px;
            }

            :global(.btn-app) {
              margin: 0;
              img {
                @media only screen and (max-width: $bp-phone) {
                  height: 28px;
                }
              }
            }
          }
        }
      }
    }
    .app-banner_right {
      align-self: auto;
      &-qr {
        display: block;
        @media only screen and (max-width: $bp-tablet) {
          left: 10px;
          bottom: 10px;
        }
      }
    }
  }

  &.bg-blue {
    color: var(--white);
    background: var(--blue);
    .app-banner_left-body-text,
    .app-banner_left-footer-right-text {
      color: var(--white);
    }
  }
}
