@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
.about-company {
  padding: 80px 0;
  @include desktopStyle {
    padding-top: get-vw(80px);
    padding-bottom: get-vw(80px);
  }
  @media only screen and (max-width: $bp-tablet) {
    padding-top: 40px;
    padding-bottom: 0;
  }

  &.b-has-text {
    .about-company_description {
      max-width: none;
    }
  }

  &_wrapper {
    background: var(--bg-blue-light-2);
    border-radius: 12px;
    @include desktopStyle {
      border-radius: get-vw(12px);
    }
    @media only screen and (max-width: $bp-tablet) {
      margin: 0 -16px;
      padding: 40px 16px 0;
    }
  }

  &_content {
    padding: 80px 0 80px 120px;
    width: 54.35%;
    @include desktopStyle {
      padding: get-vw(80px) 0 get-vw(80px) get-vw(120px);
    }
    @media only screen and (max-width: $bp-tablet) {
      width: 100%;
      padding: 0;
    }
  }

  &_text,
  &_description {
    color: var(--gray);
    margin-top: 32px;
    width: 100%;
    @include desktopStyle {
      margin-top: get-vw(32px);
    }
    @media only screen and (max-width: $bp-tablet) {
      margin-top: 16px;
    }
  }

  &_description {
    font-size: 1.6rem;
    max-width: 585px;
    @include desktopStyle {
      max-width: get-vw(585px);
    }
    @media only screen and (max-width: $bp-tablet) {
      max-width: none;
    }
    @media only screen and (max-width: $bp-phone) {
      font-size: 1.4rem;
    }
    :global {
      .rich-text {
        ul {
          li {
            &.icon-check,
            &.icon-minus {
              &::after {
                top: 2px;
                @include desktopStyle {
                  top: get-vw(2px);
                }
              }
            }
          }
        }
      }
    }
  }

  &_image {
    width: 45.65%;
    padding: 50px 50px 0;
    @include desktopStyle {
      padding: get-vw(50px) get-vw(50px) 0;
    }
    @media only screen and (max-width: $bp-tablet) {
      width: 100%;
      padding: 71px 14px 0;
    }
    img {
      display: block;
      width: auto;
      height: auto;
    }
  }
}