@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
[data-color-scheme="whiteBlack"],
[data-color-scheme="blackWhite"] {
  .block-with-image_label-icon {
    background: var(--gray-second) !important;
  }
}

[data-color-scheme="blue"] {
  .block-with-image_label-icon {
    background: #fff !important;
  }
  .block-with-image_label-icon img,
  .block-with-image_text li::after {
    filter: brightness(0%);
  }
}

.block-with-image {
  padding: 80px 0;
  @include desktopStyle {
    padding-top: get-vw(80px);
    padding-bottom: get-vw(80px);
  }
  @media only screen and (max-width: $bp-tablet) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  &:global {
    & + .partner-logos {
      padding-top: 40px;
      @include desktopStyle {
        padding-top: get-vw(40px);
      }
      @media only screen and (max-width: $bp-tablet) {
        padding-top: 0;
      }
    }
  }

  &.b-image-above {
    @media only screen and (max-width: $bp-tablet) {
      .block-with-image_wrapper {
        flex-direction: column;
        align-items: center;
      }
      .block-with-image_image {
        margin-bottom: 40px;
        margin-top: 0;
      }
    }
  }

  &.b-image-right {
    .block-with-image_image {
      order: 1;
      @media only screen and (max-width: $bp-tablet) {
        order: 0;
      }
    }
  }

  &.bg-gray {
    background: var(--gray-bg);
    padding: 120px 0;
    @include desktopStyle {
      padding-top: get-vw(120px);
      padding-bottom: get-vw(120px);
    }
    @media only screen and (max-width: $bp-tablet) {
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }

  &_wrapper {
    @media only screen and (max-width: $bp-tablet) {
      flex-direction: column-reverse;
      align-items: center;
    }
  }

  &_image {
    width: 47.5%;
    position: relative;
    @media only screen and (max-width: $bp-tablet) {
      width: 100%;
      margin-top: 40px;
      max-width: 768px;
    }

    &--width_640px {
      max-width: 640px;
      @include desktopStyle {
        max-width: get-vw(640px);
      }
    }
  }

  &_image-inner {
    width: 100%;
    border-radius: 12px;
    overflow: hidden;
    position: relative;
    &.b-fixed {
      position: sticky;
      top: 0;
      @media only screen and (max-width: $bp-tablet) {
        position: relative;
      }
    }
    @media only screen and (max-width: $bp-tablet) {
      border-radius: 8px;
    }
    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  &_content {
    width: 47.5%;
    @media only screen and (max-width: $bp-tablet) {
      width: 100%;
    }
  }

  &_content-inner {
    width: 100%;
  }

  &_label {
    position: absolute;
    top: 20px;
    left: 20px;
    max-width: 80%;
    border-radius: 8px;
    overflow: hidden;
    z-index: 2;
    @media only screen and (max-width: $bp-phone) {
      left: 10px;
      top: 10px;
    }

    &.with-border {
      border: 1px solid rgba(255, 255, 255, 0.28);
      @media only screen and (max-width: $bp-phone) {
        border-width: 2px;
      }
    }
  }

  &_label-icon {
    width: 60px;
    padding: 10px;
    $colors:
      'blue' var(--bg-blue),
      'blue_secondary' var(--bg-blue-6),
      'blue_light' var(--bg-blue-light),
      'pink' var(--bg-pink),
      'pink_dark' var(--bg-pink-dark),
      'yellow' var(--bg-yellow),
      'yellow_light' var(--bg-yellow-light),
      'brown_secondary' var(--bg-brown-3),
      'brown_dark' var(--bg-brown-dark),
      'brown' var(--bg-brown-2),
      'violet' var(--bg-violet),
      'green' var(--bg-green),
      'green_secondary' var(--bg-green-3),
      'green_three' var(--bg-green-4),
      'turquoise' var(--bg-turquoise-2);
    @each $name, $bgBlock in $colors {
      &.bg-#{$name} {
        background: $bgBlock;
      }
    }
    @include desktopStyle {
      width: get-vw(60px);
      padding: get-vw(10px);
    }
    @media only screen and (max-width: $bp-phone) {
      width: 44px;
    }
    img {
      height: auto;
      max-height: 60px;
      @include desktopStyle {
        max-height: get-vw(60px);
      }
    }
  }

  &_label-content {
    background: var(--white);
    padding: 6px 16px;
    @include desktopStyle {
      padding: get-vw(6px) get-vw(16px);
    }
    @media only screen and (max-width: $bp-tablet) {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
    }
  }

  &_label-title {
    line-height: 23px;
    @include desktopStyle {
      line-height: get-vw(23px);
    }
    @media only screen and (max-width: $bp-phone) {
      font-size: 1.4rem;
      line-height: 1;
    }
  }

  &_label-text {
    font-size: 1.4rem;
    line-height: 22px;
    @include desktopStyle {
      line-height: get-vw(22px);
    }
    @media only screen and (max-width: $bp-phone) {
      font-size: 1.1rem;
      line-height: 14px;
    }
  }

  &_text {
    color: var(--gray);
    &:not(:first-child) {
      margin-top: 32px;
      @include desktopStyle {
        margin-top: get-vw(32px);
      }
      @media only screen and (max-width: $bp-phone) {
        margin-top: 24px;
      }
    }
    :global {
      strong,
      b {
        color: var(--gray-dark);
      }
      h1, .h1,
      h2, .h2,
      h3, .h3,
      h4, .h4,
      h5, .h5,
      h6, .h6 {
        margin: 32px 0 12px;
        @include desktopStyle {
          margin-top: get-vw(32px);
          margin-bottom: get-vw(12px);
        }
        @media only screen and (max-width: $bp-tablet) {
          margin-top: 24px;
          margin-bottom: 24px;
        }
        &:first-child {
          margin-top: 0;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
      .text-3 {
        margin-bottom: 24px;
        @include desktopStyle {
          margin-bottom: get-vw(24px);
        }
      }
      ul,
      ol {
        margin: 32px 0;
        @include desktopStyle {
          margin-top: get-vw(32px);
          margin-bottom: get-vw(32px);
        }
        @media only screen and (max-width: $bp-tablet) {
          margin-top: 24px;
          margin-bottom: 24px;
        }
        &:first-child {
          margin-top: 0;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
      a {
        color: var(--blue);
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
      ul {
        & > li {
          position: relative;
          padding-left: 32px;
          padding-bottom: 8px;
          @include desktopStyle {
            padding-left: get-vw(32px);
            padding-bottom: get-vw(8px);
          }
          &:last-child {
            padding-bottom: 0;
          }
          &::after {
            content: '';
            position: absolute;
            left: 0;
            top: 6px;
            background: url('~~/app/assets/images/icons/check-circle.svg') center center no-repeat;
            background-size: contain;
            width: 20px;
            height: 20px;
            @include desktopStyle {
              width: get-vw(20px);
              height: get-vw(20px);
            }
          }
        }
      }
      ol {
        counter-reset: item;
        list-style-type: decimal;
        padding-inline-start: 32px;
        @include desktopStyle {
          padding-inline-start: get-vw(32px);
        }

        & > li {
          counter-increment: item;
          ol {
            list-style: none !important;
            & > li:before {
              content: counters(item, '.') '. ';
            }
          }
        }

        li {
          &.icon-num {
            padding-left: 4px;
            position: relative;
            list-style: none;
            margin-bottom: 16px;
            @include desktopStyle {
              padding-left: get-vw(4px);
              margin-bottom: get-vw(16px);
            }

            &::after {
              content: '';
              position: absolute;
              display: block;
              width: 24px;
              height: 24px;
              top: 4px;
              left: -32px;
              background-position: center center;
              background-size: contain;
              background-repeat: no-repeat;
              @include desktopStyle {
                width: get-vw(24px);
                height: get-vw(24px);
                top: get-vw(4px);
                left: get-vw(-32px);
              }
              @media only screen and (max-width: $bp-tablet) {
                top: 2px;
              }
              @media only screen and (max-width: $bp-phone) {
                top: 0;
              }
            }

            @for $i from 1 through 6 {
              &:nth-child(#{$i}) {
                &::after {
                  background-image: url('~~/app/assets/images/icons/circle-#{$i}.svg');
                }
              }
            }
          }
        }
      }
    }
  }

  &_link {
    margin-top: 32px;
    padding: 14px 30px;
    display: table;
    font-family: $font-main-SB;
    font-size: 1.8rem;
    line-height: 1.3;
    color: var(--blue);
    border: 2px solid var(--blue);
    border-radius: 90px;
    text-decoration: none;
    transition: all 0.3s;
    text-align: center;
    @include desktopStyle {
      margin-top: get-vw(32px);
      padding: get-vw(14px) get-vw(30px);
      border-radius: get-vw(90px);
      border-width: get-vw(2px);
    }
    @media only screen and (max-width: $bp-tablet) {
      font-size: 1.6rem;
    }
    @media only screen and (max-width: $bp-phone) {
      font-size: 1.5rem;
      min-width: 254px;
      margin-top: 24px;
    }
    &:hover {
      background: var(--blue-light-2);
      border-color: var(--blue-light-2);
      color: var(--white);
    }
  }
}
