@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
.news-post-subscribe {
  margin: 40px 0;
  overflow: hidden;
  @include desktopStyle {
    margin-top: get-vw(40px);
    margin-bottom: get-vw(40px);
  }
  @media only screen and (max-width: $bp-tablet) {
    margin-top: 32px;
    margin-bottom: 32px;
  }
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }

  &.b-has-top-icon {
    .news-post-subscribe_wrapper {
      margin-top: 95px;
      @include desktopStyle {
        margin-top: get-vw(95px);
      }
    }
  }

  &.b-has-icon {
    .news-post-subscribe_text {
      max-width: 320px;
      @include desktopStyle {
        max-width: get-vw(320px);
      }
      @media only screen and (max-width: $bp-tablet) {
        max-width: none;
      }
    }
  }

  &_wrapper {
    position: relative;
    border-radius: 12px;
    background: var(--bg-blue-light-2);
    padding: 38px 72px 38px 51px;
    @include desktopStyle {
      border-radius: get-vw(12px);
      padding: get-vw(38px) get-vw(72px) get-vw(38px) get-vw(51px);
    }
    @media only screen and (max-width: $bp-tablet) {
      padding: 32px;
    }
  }

  &_content {
    width: 425px;
    @include desktopStyle {
      width: get-vw(425px);
    }
    @media only screen and (max-width: $bp-tablet) {
      width: 100%;
      margin-bottom: 32px;
    }
  }

  &_title {
    line-height: 1.3;
    color: var(--blue);
  }

  &_text {
    color: var(--gray);
    margin-top: 11px;
    @include desktopStyle {
      margin-top: get-vw(11px);
    }
    @media only screen and (max-width: $bp-phone) {
      margin-top: 8px;
    }
  }

  &_icon {
    position: absolute;
    width: 162px;
    height: 88px;
    bottom: 0;
    left: 388px;
    @include desktopStyle {
      width: get-vw(162px);
      height: get-vw(88px);
      left: get-vw(388px);
    }
    @media only screen and (max-width: $bp-tablet) {
      position: relative;
      width: 97px;
      height: auto;
      left: unset;
      bottom: unset;
      margin: 0 auto 16px;
    }
    img {
      display: block;
      margin: 0 auto;
      width: 100%;
      height: auto;
      max-height: 100%;
    }
  }

  &_top-icon {
    position: absolute;
    width: 134px;
    height: 94px;
    left: 127px;
    bottom: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    @include desktopStyle {
      width: get-vw(134px);
      height: get-vw(94px);
      left: get-vw(127px);
    }
    @media only screen and (max-width: $bp-tablet) {
      left: 50%;
      margin-left: -67px;
    }
    img {
      display: block;
      width: 100%;
      height: auto;
      max-height: 100%;
    }
  }

  &_form {
    width: 395px;
    @include desktopStyle {
      width: get-vw(395px);
    }
    @media only screen and (max-width: $bp-tablet) {
      width: 100%;
    }
    :global {
      .btn {
        padding-left: 32px;
        padding-right: 32px;
        @include desktopStyle {
          padding-left: get-vw(32px);
          padding-right: get-vw(32px);
        }
        @media only screen and (max-width: $bp-tablet) {
          width: 100%;
          border-radius: 90px;
          justify-content: center;
        }
      }
      .form_item {
        @media only screen and (max-width: $bp-phone) {
          margin-bottom: 16px;
        }
      }
      .input {
        font-size: 1.6rem;
        padding: 26px 20px 14px 20px;
        line-height: 1.3;
        @include desktopStyle {
          padding: get-vw(26px) get-vw(20px) get-vw(14px) get-vw(20px);
        }
        &:not(:hover, :focus, .b-error) {
          border-color: var(--white);  
        }
        &:not(:placeholder-shown) + .form_placeholder,
        &:focus + .form_placeholder {
          top: 9px;
          @include desktopStyle {
            top: get-vw(9px);
          }
        }
      }
      .form_placeholder {
        top: 20px;
        left: 20px;
        @include desktopStyle {
          top: get-vw(20px);
          left: get-vw(20px);
        }
      }
    }
  }

  &_success {
    font-family: $font-main-MD;
    font-size: 1.6rem;
    line-height: 1.6;
    margin-top: 15px;
    gap: 10px;
    grid-gap: 10px;
    color: var(--gray);
    @include desktopStyle {
      margin-top: get-vw(15px);
      gap: get-vw(10px);
      grid-gap: get-vw(10px);
    }
    svg {
      flex-shrink: 0;
      width: 20px;
      height: 20px;
      @include desktopStyle {
        width: get-vw(20px);
        height: get-vw(20px);
      }
    }
  }
}
