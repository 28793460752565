@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
[data-color-scheme="blackWhite"] {
  .advantages {
    &.bg-green,
    &.bg-blue {
      background: var(--bg-blue-light-2);
      color: var(--gray-dark-2);
      .advantages_block-title,
      .advantages_block-text {
        color: var(--gray-dark-2);
      }
    }
  }
}

.advantages {
  padding: 80px 0;
  @include desktopStyle {
    padding-top: get-vw(80px);
    padding-bottom: get-vw(80px);
  }
  @media only screen and (max-width: $bp-tablet) {
    padding-top: 40px;
    padding-bottom: 80px;
  }
  &.columns-four {
    .advantages_content {
      @media only screen and (min-width: $bp-tablet + 1) {
        gap: 120px 62px;
        grid-gap: 120px 62px;
        .advantages_block {
          width: calc(25% - 47px);
          @include desktopStyle {
            width: calc(25% - get-vw(47px));
          }
        }
        @include desktopStyle {
          gap: get-vw(120px) get-vw(62px);
          grid-gap: get-vw(120px) get-vw(62px);
        }
      }
    }
  }
  &.columns-two {
    .advantages_block {
      width: percent-width-flex-items(2, 120px);
      @include desktopStyle {
        width: percent-width-flex-items(2, get-vw(120px));
      }
      @media only screen and (max-width: $bp-tablet) {
        width: percent-width-flex-items(2, 48px);
      }
      @media only screen and (max-width: $bp-phone) {
        width: 100%;
      }
    }
    .advantages_block-title,
    .advantages_block-text {
      max-width: 609px;
      @include desktopStyle {
        max-width: get-vw(609px);
      }
      @media only screen and (max-width: $bp-tablet) {
        max-width: none;
      }
    }
  }
  &.bg-green,
  &.bg-blue {
    color: var(--white);
    padding: 120px 0;
    @include desktopStyle {
      padding-top: get-vw(120px);
      padding-bottom: get-vw(120px);
    }
    @media only screen and (max-width: $bp-tablet) {
      padding-top: 80px;
      padding-bottom: 80px;
    }
    @media only screen and (max-width: $bp-phone) {
      padding-top: 40px;
      padding-bottom: 40px;
    }
    & + div {
      padding-top: 120px;
      @include desktopStyle {
        padding-top: get-vw(120px);
      }
      @media only screen and (max-width: $bp-tablet) {
        padding-top: 80px;
      }
      @media only screen and (max-width: $bp-phone) {
        padding-top: 40px;
      } 
    }
    .advantages_block-title,
    .advantages_block-text {
      color: var(--white);
    }
  }

  &.bg-blue {
    background: var(--blue);
  }
  &.bg-green {
    background: var(--green);
  }

  &.b-size_96x96 {
    .advantages_block-icon {
      @media only screen and (min-width: $bp-tablet + 1) {
        width: 96px;
        height: 96px;
        @include desktopStyle {
          width: get-vw(96px);
          height: get-vw(96px);
        }
      }
    }
  }

  &.is-wide-title {
    .advantages_title {
      max-width: 1111px; // 1095 + 16
      @include desktopStyle {
        max-width: get-vw(1111px);
      }
    }
    .advantages_descr {
      max-width: 486px;
      @include desktopStyle {
        max-width: get-vw(486px);
      }
    }
  }

  &.design-tiles {
    @media only screen and (max-width: $bp-tablet) {
      padding-bottom: 40px;
    }

    .advantages_content {
      gap: 37px 32px;
      grid-gap: 37px 32px;
      margin-top: 60px;
      @include desktopStyle {
        gap: get-vw(37px) get-vw(32px);
        grid-gap: get-vw(37px) get-vw(32px);
        margin-top: get-vw(60px);
      }
      @media only screen and (max-width: $bp-tablet) {
        margin-top: 48px;
        gap: 12px;
        grid-gap: 12px;
      }
      @media only screen and (max-width: $bp-phone) {
        margin-top: 32px;
      }
    }
    .advantages_block {
      display: flex;
      flex-direction: column;
      background: var(--blue-light);
      border-radius: 12px;
      padding: 40px;
      width: percent-width-flex-items(3, 32px);
      min-height: 320px;
      @include desktopStyle {
        border-radius: get-vw(12px);
        padding: get-vw(40px);
        width: percent-width-flex-items(3, get-vw(32px));
        min-height: get-vw(320px);
      }
      @media only screen and (max-width: $bp-tablet) {
        display: block;
        padding: 24px;
        width: percent-width-flex-items(2, 12px);
        min-height: auto;
        border-radius: 8px;
      }
    }
    .advantages_block-icon {
      margin-bottom: 30px;
      @include desktopStyle {
        margin-bottom: get-vw(30px);
      }
      @media only screen and (max-width: $bp-tablet) {
        margin-bottom: 24px;
        width: 48px;
        height: 48px;
      }
    }
    .advantages_block-title {
      line-height: 1.3;
      color: var(--gray-dark);
      margin-top: auto;
      @media only screen and (max-width: $bp-tablet) {
        margin-top: 0;
        font-family: $font-main-B;
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 1.6;
      }
    }
    .advantages_block-text {
      color: var(--gray-dark);
      margin-top: 8px;
      @include desktopStyle {
        margin-top: get-vw(8px);
      }
      @media only screen and (max-width: $bp-tablet) {
        font-size: 1.4rem;
        line-height: 1.6;
      }
    }

    &.columns-four {
      .advantages_block {
        width: percent-width-flex-items(4, 32px);
        @include desktopStyle {
          width: percent-width-flex-items(4, get-vw(32px));
        }
        @media only screen and (max-width: $bp-tablet) {
          width: percent-width-flex-items(2, 12px);
        }
      }
    }
    &.columns-two {
      .advantages_block {
        width: percent-width-flex-items(2, 32px);
        @include desktopStyle {
          width: percent-width-flex-items(2, get-vw(32px));
        }
        @media only screen and (max-width: $bp-tablet) {
          width: percent-width-flex-items(2, 12px);
        }
      }
    }
  }

  &_title {
    max-width: 710px;
    padding-right: 16px;
    @include desktopStyle {
      max-width: get-vw(710px);
      padding-right: get-vw(16px);
    }
  }

  &_descr {
    font-weight: 500;
    margin-bottom: 0;
    margin-top: 32px;
    max-width: 722px;;
    @include desktopStyle {
      margin-top: get-vw(32px);
      max-width: get-vw(722px);
    }
    @media only screen and (max-width: $bp-phone) {
      margin-top: 16px;
      max-width: none;
    }

    & + .advantages_content {
      margin-top: 80px;
      @include desktopStyle {
        margin-top: get-vw(80px);
      }
      @media only screen and (max-width: $bp-tablet) {
        margin-top: 48px;
      }
      @media only screen and (max-width: $bp-phone) {
        margin-top: 32px;
      }
    }
  }

  &_content {
    gap: 120px;
    grid-gap: 120px;
    margin-top: 120px;
    @include desktopStyle {
      margin-top: get-vw(120px);
      gap: get-vw(120px);
      grid-gap: get-vw(120px);
    }
    @media only screen and (max-width: $bp-tablet) {
      grid-gap: 48px;
      margin-top: 48px;
      font-size: 1.8rem;
    }
    @media only screen and (max-width: $bp-phone) {
      margin-top: 32px;
    }
  }

  &_block {
    width: calc(33.333% - 80px);
    @include desktopStyle {
      width: calc(33.333% - get-vw(80px));
    }
    @media only screen and (max-width: $bp-tablet) {
      width: calc(50% - 24px);
    }
    @media only screen and (max-width: $bp-phone) {
      width: 100%;
    }
  }

  &_block-title {
    color: var(--blue);
  }

  &_block-icon {
    width: 64px;
    height: 64px;
    margin-bottom: 50px;
    @include desktopStyle {
      margin-bottom: get-vw(50px);
      width: get-vw(64px);
      height: get-vw(64px);
    }
    @media only screen and (max-width: $bp-tablet) {
      width: 56px;
      height: 56px;
      margin-bottom: 24px;
    }
    img {
      max-height: 100%;
      display: block;
      width: auto;
      height: auto;
    }
  }

  &_block-text {
    color: var(--gray);
    margin-top: 20px;
    @include desktopStyle {
      margin-top: get-vw(20px);
    }
    @media only screen and (max-width: $bp-tablet) {
      margin-top: 12px;
    }

    &-inner {
      display: inline;
    }
  }

  &_tooltip {
    &-toggle {
      cursor: pointer;
      color: var(--gray-light);
      display: inline-block;
      transition: color .3s;
      svg {
        position: relative;
        display: block;
        color: inherit;
        width: 20px;
        height: 20px;
        top: 2px;
        @include desktopStyle {
          width: get-vw(20px);
          height: get-vw(20px);
          top: get-vw(2px);
        }
        @media only screen and (max-width: $bp-tablet) {
          top: 4px;
        }
      }
      &:hover {
        color: var(--blue);
      }
    }

    &:global(.react-tooltip) {
      z-index: 3;
      color: var(--gray);
      font-weight: 500;
      background: var(--white);
      border-radius: 12px;
      box-shadow: 0px 4px 20px 0px rgba(var(--bg-blue-4), .1);
      white-space: pre-line;
      max-width: 400px;
      padding: 24px;
      @include desktopStyle {
        max-width: get-vw(400px);
        padding: get-vw(24px);
      }
      @media only screen and (max-width: $bp-tablet) {
        padding: 16px;
      }

      &:global(.react-tooltip__show) {
        opacity: 1;
        & + .advantages_tooltip-toggle {
          color: var(--blue);
        }
      }
    }
  }
}