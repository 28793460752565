@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
[data-color-scheme="whiteBlack"],
[data-color-scheme="blackWhite"],
[data-color-scheme="blue"] {
  .cards_block {
    background: transparent !important;
    border: 1px solid var(--gray-dark);
    :global(.bg-white) {
      border-color: var(--gray-dark);
      border-width: 1px;
    }
  }
}
[data-color-scheme="blackWhite"] {
  .cards_block-label {
    color: #151413;
  }
}

.cards {
  padding: 80px 0;
  color: var(--gray-dark);
  @include desktopStyle {
    padding-top: get-vw(80px);
    padding-bottom: get-vw(80px);
  }
  @media only screen and (max-width: $bp-tablet) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  &.b-columns-two {
    .cards_block {
      width: calc(50% - 16px);
      @include desktopStyle {
        width: calc(50% - get-vw(16px));
      }
      @media only screen and (max-width: $bp-tablet) {
        width: calc(50% - 16px);
        padding: 32px;
      }
      @media only screen and (max-width: $bp-phone) {
        width: calc(50% - 8px);
        padding: 24px;
      }
      @media only screen and (max-width: $bp-phone-sm) {
        width: 100%;
      }
      // 65.15vw
    }
    .cards_block-text {
      max-width: 506px;
      margin-top: 12px;
      @include desktopStyle {
        max-width: get-vw(506px);
        margin-top: get-vw(12px);
      }
      @media only screen and (max-width: $bp-phone) {
        font-size: 1.8rem;
        margin-top: 8px;
      }
    }
    .cards_block-image {
      @media only screen and (max-width: $bp-phone-sm) {
        height: 65.15vw;
      }
    }
  }

  &_top {
    gap: 16px;
    grid-gap: 16px;
    margin-bottom: 60px;
    @include desktopStyle {
      margin-bottom: get-vw(60px);
    }
    .cards_link-arrow {
      display: inline-flex;
      svg {
        @include desktopStyle {
          width: get-vw(13px);
          height: get-vw(14px);
        }
      }
    }
    @media only screen and (max-width: $bp-tablet) {
      margin-bottom: 46px;
    }
    @media only screen and (max-width: $bp-phone) {
      margin-bottom: 32px;
      .cards_link-arrow {
        display: none;
      }
    }
  }

  &_bottom {
    display: none;
    text-align: center;
    margin-top: 32px;
    @include desktopStyle {
      margin-top: get-vw(32px);
    }
    @media only screen and (max-width: $bp-phone) {
      display: block;
    }
    .cards_link-arrow {
      display: inline-flex;
      color: var(--blue);
      border: 2px solid var(--blue);
      padding: 14px 38px;
      border-radius: 30px;
    }
  }

  &_content {
    gap: 32px;
    grid-gap: 32px;
    @include desktopStyle {
      gap: get-vw(32px);
      grid-gap: get-vw(32px);
    }
    @media only screen and (max-width: $bp-tablet) {
      justify-content: center;
    }
    @media only screen and (max-width: $bp-phone) {
      gap: 16px;
      grid-gap: 16px;
    }
  }

  &_block {
    width: calc(33.333% - 22px);
    position: relative;
    text-align: center;
    padding: 50px;
    border-radius: 12px;
    overflow: hidden;
    @include desktopStyle {
      width: calc(33.333% - get-vw(22px));
      padding: get-vw(50px);
    }
    @media only screen and (max-width: $bp-tablet) {
      width: calc(50% - 16px);
      padding: 32px;
    }
    @media only screen and (max-width: $bp-phone) {
      width: calc(50% - 8px);
      padding: 24px;
    }
    @media only screen and (max-width: $bp-phone-sm) {
      width: 100%;
    }
    &:hover {
      .cards_block-image {
        transform: scale(1.02);
      }
      .cards_block-btn {
        transform: scale(1.05);
      }
    }
    $colors:
      "blue" var(--bg-blue) var(--bg-label-blue),
      "yellow" var(--bg-yellow) var(--bg-label-yellow),
      "green" var(--bg-green) var(--bg-label-green),
      'green_light' var(--bg-green-light-2) var(--bg-green-light),
      'green_dark' var(--bg-green) var(--bg-green-light-2),
      'blue_light' var(--bg-blue-light) var(--bg-label-blue-2),
      'blue_sky' var(--bg-blue-sky) var(--bg-blue-light-4),
      'violet_light' var(--bg-violet-light) var(--bg-violet-light-2),
      'brown' var(--bg-brown) var(--bg-brown-light),
      'brown_light' var(--bg-brown-light) var(--bg-brown-light-3),
      'pink' var(--bg-pink) var(--pink-light),
      'pink_light' var(--bg-pink-light) var(--bg-pink-light-2),
      'yellow_secondary' var(--bg-yellow) var(--bg-yellow-light-3),
      'turquoise' var(--bg-turquoise) var(--bg-turquoise-light);

    @each $name, $bgBlock, $bgLabel in $colors {
      &.bg-#{$name} {
        background: $bgBlock;
        .cards_block-label {
          background: $bgLabel;
        }
      }
    }
  }

  &_block-over-link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    display: block;
    text-decoration: none;
    outline: none;
  }

  &_block-label {
    display: table;
    margin: 0 auto 27px;
    font-size: 2rem;
    padding: 6px 20px;
    border-radius: 60px;
    @include desktopStyle {
      padding: get-vw(6px) get-vw(20px);
      margin-bottom: get-vw(27px);
    }
    @media only screen and (max-width: $bp-tablet) {
      font-size: 1.8rem;
      margin-bottom: 24px;
    }
    @media only screen and (max-width: $bp-phone) {
      font-size: 1.6rem;
      padding: 3px 17px;
    }
  }

  &_block-title {
    @media only screen and (max-width: $bp-phone) {
      font-size: 3.2rem;
    }
  }

  &_block-text {
    font-family: $font-main-SB;
    font-size: 2.4rem;
    line-height: 1.5;
    max-width: 295px;
    margin: 0 auto;
    @include desktopStyle {
      max-width: get-vw(295px);
    }
    @media only screen and (max-width: $bp-desktop-sm) {
      font-size: 2rem;
    }
    @media only screen and (max-width: $bp-phone) {
      font-size: 1.8rem;
      line-height: 1.3;
    }
    @media only screen and (max-width: $bp-phone-sm) {
      font-size: 2rem;
    }
  }

  &_block-image {
    height: 418px;
    margin: auto -50px -50px;
    transition: all 0.3s;
    transform-origin: center bottom;
    padding-top: 3px;
    @include desktopStyle {
      height: get-vw(418px);
      margin-bottom: get-vw(-50px);
      margin-left: get-vw(-50px);
      margin-right: get-vw(-50px);
    }
    @media only screen and (max-width: $bp-tablet) {
      height: calc(36vw + 24px);
      margin: auto -32px -32px;
    }
    @media only screen and (max-width: $bp-phone) {
      padding-top: 24px;
      margin: auto -24px -24px;
    }
    @media only screen and (max-width: $bp-phone-sm) {
      height: 72vw;
      padding-top: 0;
      margin-top: 24px;
    }
    img {
      display: block;
      max-height: 100%;
      width: 100%;
      height: auto;
      object-fit: contain;
      object-position: bottom;
    }
  }

  &_block-btn {
    position: absolute;
    z-index: 3;
    right: 30px;
    bottom: 30px;
    &:global(.btn.bg-white) {
      &:hover {
        background: var(--white);
        border-color: var(--white);
        color: var(--gray-dark);
      } 
    }
    @include desktopStyle {
      right: get-vw(30px);
      bottom: get-vw(30px);
      svg {
        width: get-vw(16px);
        height: get-vw(16px);
      }
    }
    @media only screen and (max-width: $bp-phone) {
      right: 24px;
      bottom: 24px;
    }
  }
}
