@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
.news-post-images-slider {
  position: relative;
  margin: 40px 0;
  @include desktopStyle {
    margin-top: get-vw(40px);
    margin-bottom: get-vw(40px);
  }
  @media only screen and (max-width: $bp-tablet) {
    margin-top: 32px;
    margin-bottom: 32px;
  }
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }

  :global(.slick-arrow) {
    background: var(--white);
    font-size: 0;
    line-height: 0;
    padding: 0;
    width: 48px;
    height: 48px;
    cursor: pointer;
    position: absolute;
    z-index: 2;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    border-radius: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 12px 12px;
    @include desktopStyle {
      width: get-vw(48px);
      height: get-vw(48px);
      background-size: get-vw(12px) get-vw(12px);
    }
    @media only screen and (max-width: $bp-tablet) {
      width: 32px;
      height: 32px;
      background-size: 10px 10px;
    }
    @media only screen and (max-width: $bp-phone) {
      width: 28px;
      height: 28px;
      background-size: 8px 8px;
    }
  }

  :global(.slick-next) {
    right: 24px;
    background-image: url('~~/app/assets/images/icons/arrow-right.svg');
    @include desktopStyle {
      right: get-vw(24px);
    }
    @media only screen and (max-width: $bp-tablet) {
      right: 16px;
    }
    @media only screen and (max-width: $bp-phone) {
      right: 8px;
    }
  }

  :global(.slick-prev) {
    left: 24px;
    background-image: url('~~/app/assets/images/icons/arrow-left.svg');
    @include desktopStyle {
      left: get-vw(24px);
    }
    @media only screen and (max-width: $bp-tablet) {
      left: 16px;
    }
    @media only screen and (max-width: $bp-phone) {
      left: 8px;
    }
  }
  
  &_block {
    width: 100%;
    line-height: 1;
    img {
      border-radius: 12px;
      display: block;
      @include desktopStyle {
        border-radius: get-vw(12px);
      }
      @media only screen and (max-width: $bp-phone) {
        border-radius: 8px;
      }
    }
  }

  &_footnote {
    margin-top: 16px;
    font-size: 1.6rem;
    line-height: 1.6;
    color: var(--gray-light-2);
    @include desktopStyle {
      margin-top: get-vw(16px);
    }
    @media only screen and (max-width: $bp-phone) {
      font-size: 1.4rem;
      margin-top: 8px;
    }
  }
}