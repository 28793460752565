@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
.partner-logos {
  padding: 120px 0;
  @include desktopStyle {
    padding-top: get-vw(120px);
    padding-bottom: get-vw(120px);
  }
  @media only screen and (max-width: $bp-tablet) {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  @media only screen and (max-width: $bp-phone) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  &_wrapper {
    gap: 40px 90px;
    grid-gap: 40px 90px;
    @include desktopStyle {
      gap: get-vw(40px) get-vw(90px);
      grid-gap: get-vw(40px) get-vw(90px);
    }
    @media only screen and (max-width: $bp-tablet) {
      gap: 20px;
      grid-gap: 20px;
    }
  }

  &_block {
    max-width: 200px;
    @include desktopStyle {
      max-width: get-vw(200px);
    }
    @media only screen and (max-width: $bp-tablet) {
      max-width: 102px;
    }
  }

  &_link {
    display: block;
    text-decoration: none;
    img {
      display: block;
      margin: 0 auto;
    }
  }
}