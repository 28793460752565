@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
.news-post-hero {

  :global(.wr.d-f) {
    padding-right: 16px;
    @include desktopStyle {
      padding-right: get-vw(16px);
    }
  }

  &_content {
    width: calc(100% - 408px);
    @include desktopStyle {
      width: calc(100% - get-vw(408px));
    }
    @media only screen and (max-width: $bp-tablet) {
      width: 100%;
    }
  }

  &_image {
    img {
      width: 100%;
      display: block;
      border-radius: 12px;
      @include desktopStyle {
        border-radius: get-vw(12px);
      }
      @media only screen and (max-width: $bp-phone) {
        border-radius: 8px;
      }
    }
  }

  &_image-footnote {
    color: var(--gray-light-2);
    font-size: 1.6rem;
    margin-top: 16px;
    @include desktopStyle {
      margin-top: get-vw(16px);
    }
    @media only screen and (max-width: $bp-phone) {
      font-size: 1.4rem;
      margin-top: 8px;
    }
  }

  &_date {
    color: var(--gray-light);
    font-size: 1.6rem;
    margin-top: 40px;
    gap: 24px;
    grid-gap: 24px;
    @include desktopStyle {
      margin-top: get-vw(40px);
      gap: get-vw(24px);
      grid-gap: get-vw(24px);
    }
    @media only screen and (max-width: $bp-tablet) {
      margin-top: 32px;
    }
    @media only screen and (max-width: $bp-phone) {
      font-size: 1.4rem;
      margin-top: 24px;
    }
  }

  &_date-update {
    gap: 8px;
    grid-gap: 8px;
    @include desktopStyle {
      gap: get-vw(8px);
      grid-gap: get-vw(8px);
    }
  }

  &_title {
    margin-top: 20px;
    @include desktopStyle {
      margin-top: get-vw(20px);
    }
    @media only screen and (max-width: $bp-phone) {
      margin-top: 8px;
      line-height: 1.3;
    }
  }

  &_posts {
    width: 348px;
    font-size: 1.6rem;
    top: 0;
    @media only screen and (max-width: $bp-tablet) {
      display: none;
    }
    @include desktopStyle {
      width: get-vw(348px);
    }
  }

  &_posts-block {
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid var(--gray-second);
    @include desktopStyle {
      padding-bottom: get-vw(24px);
      margin-bottom: get-vw(24px);
      border-bottom-width: get-vw(1px);
    }
    &:last-child {
      border: none;
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }

  &_posts-link {
    color: var(--gray-dark);
    text-decoration: none;
    &:hover {
      color: var(--blue);
    }
  }

  &_posts-date {
    color: var(--gray-light);
    margin-top: 12px;
    @include desktopStyle {
      margin-top: get-vw(12px);
    }
  }
}