@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
.slider {
  margin-top: 72px;
  color: var(--gray);
  padding-bottom: 58px;
  @include desktopStyle {
    margin-top: get-vw(72px);
    padding-bottom: get-vw(58px);
  }
  @media only screen and (max-width: $bp-tablet) {
    margin-top: 50px;
    font-size: 1.8rem;
    padding-bottom: 39px;
  }
  @media only screen and (max-width: $bp-phone) {
    margin-top: 32px;
    font-size: 1.6rem;
  }

  :global(.slick-arrow) {
    padding: 0;
    background: transparent;
    cursor: pointer;
    outline: none;
    font-size: 0;
    position: absolute;
    z-index: 2;
    bottom: 0;
    width: 24px;
    height: 24px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    border: none;
    transition: all 0.3s;
    @include desktopStyle {
      width: get-vw(24px);
      height: get-vw(24px);
    }
    &:not(.slick-disabled):hover {
      filter: invert(51%) sepia(93%) saturate(284%) hue-rotate(163deg) brightness(89%) contrast(105%);
    }
  }
  :global(.slick-disabled) {
    opacity: 0.6;
  }
  :global(.slick-prev) {
    left: 0;
    background-image: url('~~/app/assets/images/icons/arrow-left-v2.svg');
  }
  :global(.slick-next) {
    left: 48px;
    background-image: url('~~/app/assets/images/icons/arrow-right-v2.svg');
    @include desktopStyle {
      left: get-vw(48px);
    }
  }

  &_top {
    gap: 8px;
    grid-gap: 8px;
  }

  &_title {
    font-family: $font-main;
    font-size: 9.2rem;
    line-height: .83;
    color: var(--blue);
    @media only screen and (max-width: $bp-tablet) {
      font-size: 6.4rem;
    }
    @media only screen and (max-width: $bp-phone) {
      font-size: 5.4rem;
    }
  }

  &_text {
    margin-top: 12px;
    @include desktopStyle {
      margin-top: get-vw(12px);
    }
  }
}