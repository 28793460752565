@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
.block-with-right-image {
  padding: 120px 0;
  @include desktopStyle {
    padding-top: get-vw(120px);
    padding-bottom: get-vw(120px);
  }
  @media only screen and (max-width: $bp-tablet) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  &.bg-gray {
    background: var(--gray-bg);
  }

  &_wrapper {
    @media only screen and (max-width: $bp-tablet) {
      flex-direction: column-reverse;
      align-items: center;
    }
  }

  &_content {
    width: 45%;
    max-width: 592px;
    @include desktopStyle {
      max-width: get-vw(592px);
    }
    @media only screen and (max-width: $bp-tablet) {
      width: 100%;
      max-width: none;
    }
  }

  &_title {
    margin-bottom: 16px;
    @media only screen and (max-width: $bp-tablet) {
      margin-bottom: 24px;
    }
  }

  &_text {
    color: var(--gray);
  }

  &_logo {
    margin-top: auto;
    padding-top: 32px;
    @include desktopStyle {
      padding-top: get-vw(32px);
    }
    @media only screen and (max-width: $bp-tablet) {
      padding-top: 24px;
      width: 50%;
    }
  }

  &_image {
    width: 50%;
    @media only screen and (max-width: $bp-tablet) {
      width: 100%;
      max-width: 500px;
      margin-bottom: 40px;
    }
    img {
      display: block;
      margin-left: auto;
      @media only screen and (max-width: $bp-tablet) {
        margin-right: auto;
        max-width: 76.5%;
      }
    }
  }
}