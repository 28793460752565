@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
.awards-description {
  padding: 120px 0;
  background: var(--gray-bg);
  @include desktopStyle {
    padding-top: get-vw(120px);
    padding-bottom: get-vw(120px);
  }
  @media only screen and (max-width: $bp-tablet) {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  @media only screen and (max-width: $bp-phone) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  &_wrapper {
    gap: 80px;
    grid-gap: 80px;
    @include desktopStyle {
      gap: get-vw(80px);
      grid-gap: get-vw(80px);
    }
    @media only screen and (max-width: $bp-tablet) {
      gap: 24px;
      grid-gap: 24px;
    }
  }

  &_left,
  &_right {
    width: calc(50% - 40px);
    @include desktopStyle {
      width: calc(50% - get-vw(40px));
    }
    @media only screen and (max-width: $bp-tablet) {
      width: 100%;
    }
  }

  &_label {
    display: inline-block;
    background: var(--blue);
    padding: 2px 20px;
    color: var(--white);
    margin-bottom: 24px;
    border-radius: 40px;
    @include desktopStyle {
      padding: get-vw(2px) get-vw(20px);
      margin-bottom: get-vw(24px);
      border-radius: get-vw(40px);
    }
    @media only screen and (max-width: $bp-tablet) {
      padding: 0 16px;
      margin-bottom: 16px;
    }
  }

  &_text {
    margin-top: 24px;
    font-size: 2.2rem;
    color: var(--gray);
    @include desktopStyle {
      margin-top: get-vw(24px);
    }
    @media only screen and (max-width: $bp-tablet) {
      font-size: 1.8rem;
      margin-top: 16px;
    }
  }

  &_logos {
    gap: 40px 50px;
    grid-gap: 40px 50px;
    margin-top: 60px;
    @include desktopStyle {
      gap: get-vw(40px) get-vw(50px);
      grid-gap: get-vw(40px) get-vw(50px);
      margin-top: get-vw(60px);
    }
    @media only screen and (max-width: $bp-tablet) {
      margin-top: 40px;
      gap: 16px 24px;
      grid-gap: 16px 24px;
      flex-wrap: wrap;
    }
  }

  &_description {
    color: var(--gray);
  }

  &_logos-item {
    max-width: 200px;
    @include desktopStyle {
      max-width: get-vw(200px);
    }
    img {
      display: block;
    }
  }
}
