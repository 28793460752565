@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
.block-with-icons {
  padding: 120px 0;
  @include desktopStyle {
    padding-top: get-vw(120px);
    padding-bottom: get-vw(120px);
  }
  @media only screen and (max-width: $bp-tablet) {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  @media only screen and (max-width: $bp-phone) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  &_wrapper {
    @media only screen and (max-width: $bp-tablet) {
      justify-content: center;
    }
  }

  &_image {
    width: 40%;
    img {
      display: block;
    }
    @media only screen and (max-width: $bp-tablet) {
      width: 100%;
      max-width: 500px;
      margin-bottom: 32px;
      img {
        margin: 0 auto;
        max-width: 77%;
      }
    }
  }

  &_content {
    width: 49.25%;
    @media only screen and (max-width: $bp-tablet) {
      width: 100%;
    }
  }

  &_btn {
    margin-top: 32px;
    @include desktopStyle {
      margin-top: get-vw(32px);
    }
    &:global(.btn) {
      padding-left: 32px;
      padding-right: 24px;
      @include desktopStyle {
        padding-left: get-vw(32px);
        padding-right: get-vw(24px);
        svg {
          width: get-vw(16px);
          height: get-vw(16px);
        }
      }
      @media only screen and (max-width: $bp-tablet) {
        margin-top: 24px;
        padding: 10px 18px 10px 22px;
      }
      @media only screen and (max-width: $bp-phone) {
        margin-top: 16px;
        svg {
          width: 12px;
          height: 12px;
        }
      }
    }
  }

  &_items {
    gap: 48px 16px;
    grid-gap: 48px 16px;
    margin-top: 64px;
    @include desktopStyle {
      margin-top: get-vw(64px);
      gap: get-vw(48px) get-vw(16px);
      grid-gap: get-vw(48px) get-vw(16px);
    }
    @media only screen and (max-width: $bp-tablet) {
      margin-top: 48px;
    }
    @media only screen and (max-width: $bp-phone) {
      margin-top: 32px;
    }
  }

  &_item {
    width: calc(50% - 16px);
    color: var(--gray);
    @include desktopStyle {
      width: calc(50% - get-vw(16px));
    }
    @media only screen and (max-width: $bp-tablet) {
      font-size: 1.8rem;
    }
    @media only screen and (max-width: $bp-phone) {
      width: 100%;
    }
  }

  &_item-icon {
    width: 64px;
    height: 64px;
    margin-bottom: 32px;
    @include desktopStyle {
      width: get-vw(64px);
      height: get-vw(64px);
      margin-bottom: get-vw(32px);
    }
    @media only screen and (max-width: $bp-tablet) {
      width: 56px;
      height: 56px;
      margin-bottom: 24px;
    }
    img {
      display: block;
      max-height: 100%;
    }
  }
}
