@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
[data-color-scheme="blue"] {
  .block-with-texts_text li::after {
    filter: brightness(0%);
  }
}

.block-with-texts {
  padding: 120px 0;
  @include desktopStyle {
    padding-top: get-vw(120px);
    padding-bottom: get-vw(120px);
  }
  @media only screen and (max-width: $bp-tablet) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  &.bg-gray {
    background-color: var(--gray-bg);
  }

  &_wrapper {
    @media only screen and (max-width: $bp-tablet) {
      display: block;
    }
  }
  &_left {
    width: 44.75%;
    @media only screen and (max-width: $bp-tablet) {
      width: 100%;
    }

    &-inner {
      position: relative;
      @media only screen and (max-width: $bp-tablet) {
        display: block;
      }
    }

    &-text {
      max-width: 609px;
      z-index: 1;
      @include desktopStyle {
        max-width: get-vw(609px);
      }
      @media only screen and (max-width: $bp-tablet) {
        max-width: none;
        margin-bottom: 32px;
      }
    }
    &-image {
      order: 1;
      margin-top: 106px;
      display: block;
      width: 100%;
      height: auto;
      @include desktopStyle {
        margin-top: get-vw(106px);
      }
      @media only screen and (max-width: $bp-tablet) {
        width: auto;
        margin: 0 auto 32px;
        img {
          max-width: 82%;
          margin: 0 auto;
        }
      }
      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }
    p {
      margin-top: 32px;
      margin-bottom: 0;
      max-width: 485px;
      color: var(--gray);
      @include desktopStyle {
        margin-top: get-vw(32px);
        max-width: get-vw(485px);
      }
      @media only screen and (max-width: $bp-tablet) {
        margin-top: 16px;
        max-width: 83.733%;
      }
    }
  }
  &_label {
    font-weight: 500;
    display: inline-block;
    background: var(--blue);
    padding: 2px 20px;
    color: var(--white);
    margin-bottom: 32px;
    border-radius: 40px;
    @include desktopStyle {
      padding: get-vw(2px) get-vw(20px);
      margin-bottom: get-vw(32px);
      border-radius: get-vw(40px);
    }
    @media only screen and (max-width: $bp-tablet) {
      padding: 0 16px;
      margin-bottom: 16px;
    }
  }
  &_link {
    margin-top: 32px;
    padding: 14px 30px;
    display: table;
    font-family: $font-main-SB;
    font-size: 1.8rem;
    line-height: 1.3;
    color: var(--blue);
    border: 2px solid var(--blue);
    border-radius: 90px;
    text-decoration: none;
    transition: all 0.3s;
    text-align: center;
    max-width: 485px;
    @include desktopStyle {
      margin-top: get-vw(32px);
      padding: get-vw(14px) get-vw(30px);
      border-width: get-vw(2px);
      max-width: get-vw(485px);
    }
    @media only screen and (max-width: $bp-tablet) {
      margin-top: 16px;
      font-size: 1.6rem;
      padding: 10px 22px;
      max-width: none;
    }
    &:hover {
      background: var(--blue-light-2);
      border-color: var(--blue-light-2);
      color: var(--white);
    }
  }

  &_right {
    width: 48.188%;
    @media only screen and (max-width: $bp-tablet) {
      width: 100%;
    }
    .block-with-texts_link {
      margin-top: 48px;
      @include desktopStyle {
        margin-top: get-vw(48px);
      }
      @media only screen and (max-width: $bp-tablet) {
        margin-top: 32px;
      }
    }
  }
  &_text {
    color: var(--gray);

    :global {
      a {
        color: var(--blue);
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
      h4, .h4 {
        margin-bottom: 16px;
        @include desktopStyle {
          margin-bottom: get-vw(16px);
        }
        @media only screen and (max-width: $bp-phone) {
          margin-bottom: 24px;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
      h5, .h5 {
        margin: 32px 0 22px;
        @include desktopStyle {
          margin: get-vw(32px) 0 get-vw(22px);
        }
        @media only screen and (max-width: $bp-tablet) {
          margin: 32px 0 18px;
        }
        &:first-child {
          margin-top: 0;
        }
        &:last-child {
          margin-bottom: 0;
        }
        & + ul,
        & + ol {
          margin-top: 0;
        }
      }

      ul,
      ol {
        margin: 32px 0;
        @include desktopStyle {
          margin-top: get-vw(32px);
          margin-bottom: get-vw(32px);
        }
        @media only screen and (max-width: $bp-tablet) {
          margin-top: 24px;
          margin-bottom: 24px;
        }

        &:first-child {
          margin-top: 0;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }

      ul {
        list-style: none !important;
        & > li {
          position: relative;
          padding-left: 32px;
          padding-bottom: 20px;
          @include desktopStyle {
            padding-left: get-vw(32px);
            padding-bottom: get-vw(20px);
          }
          &:last-child {
            padding-bottom: 0;
          }
          &::after {
            content: '';
            position: absolute;
            left: 0;
            top: 6px;
            background: url('~~/app/assets/images/icons/check-circle.svg') center center no-repeat;
            background-size: contain;
            width: 20px;
            height: 20px;
            @include desktopStyle {
              top: get-vw(6px);
              width: get-vw(20px);
              height: get-vw(20px);
            }
          }
        }
      }

      ol {
        counter-reset: item;
        list-style-type: decimal;
        padding-inline-start: 32px;
        @include desktopStyle {
          padding-inline-start: get-vw(32px);
        }

        & > li {
          counter-increment: item;
          ol {
            list-style: none !important;
            & > li:before {
              content: counters(item, '.') '. ';
            }
          }
        }

        li {
          &.icon-num {
            padding-left: 4px;
            position: relative;
            list-style: none;
            margin-bottom: 16px;
            @include desktopStyle {
              padding-left: get-vw(4px);
              margin-bottom: get-vw(16px);
            }

            &::after {
              content: '';
              position: absolute;
              display: block;
              width: 24px;
              height: 24px;
              top: 4px;
              left: -32px;
              background-position: center center;
              background-size: contain;
              background-repeat: no-repeat;
              @include desktopStyle {
                width: get-vw(24px);
                height: get-vw(24px);
                top: get-vw(4px);
                left: get-vw(-32px);
              }
              @media only screen and (max-width: $bp-tablet) {
                top: 2px;
              }
              @media only screen and (max-width: $bp-phone) {
                top: 0;
              }
            }

            @for $i from 1 through 6 {
              &:nth-child(#{$i}) {
                &::after {
                  background-image: url('~~/app/assets/images/icons/circle-#{$i}.svg');
                }
              }
            }
          }
        }
      }
    }
  }

  &--left_static {
    .block-with-texts_left {
      &-inner {
        height: 100%;
      }
      &-image {
        margin-top: 80px;
        width: auto;
        @include desktopStyle {
          margin-top: get-vw(80px);
        }
        @media only screen and (max-width: $bp-tablet) {
          max-width: 100%;
          margin: 0 auto 32px;
        }
        img {
          width: auto;
        }
      }
    }
  }

  &--lower_left_corner_static {
    .block-with-texts_left {
      &-inner {
        height: 100%;
      }
      &-image {
        margin-top: auto;
        padding-top: 80px;
        width: auto;
        max-width: 248px;
        @include desktopStyle {
          padding-top: get-vw(80px);
          max-width: get-vw(248px);
        }
        @media only screen and (max-width: $bp-tablet) {
          max-width: 100%;
          margin: 0 auto 32px;
          padding-top: 0;
        }
      }
    }
  }
  &--lower_right_corner_absolute {
    .block-with-texts_left {
      &-inner {
        padding-bottom: 55px;
        @include desktopStyle {
          padding-bottom: get-vw(55px);
        }
        @media only screen and (max-width: $bp-tablet) {
          padding-bottom: 0;
        }
      }
      &-text {
        max-width: 485px;
        @include desktopStyle {
          max-width: get-vw(485px);
        }
        @media only screen and (max-width: $bp-tablet) {
          max-width: none;
        }
      }
      &-image {
        margin: 0;
        width: auto;
        max-width: 256px; // 100%
        position: absolute;
        right: 0;
        bottom: 0;
        img {
          width: 100%;
        }
        @include desktopStyle {
          max-width: get-vw(256px);
        }
        @media only screen and (max-width: $bp-tablet) {
          position: static;
          max-width: 100%;
          margin: 0 auto 32px;
          img {
            margin: 0 auto;
          }
        }
      }
    }
  }
  &--lower_right_corner_static {
    .block-with-texts_left {
      &-image {
        margin-top: 18px;
        margin-left: auto;
        width: auto;
        max-width: 262px; // 100%
        @include desktopStyle {
          margin-top: get-vw(18px);
          max-width: get-vw(262px);
        }
        @media only screen and (max-width: $bp-tablet) {
          max-width: 57%;
          margin: 0 auto 32px;
          img {
            margin: 0 auto;
            max-width: none;
          }
        }
      }
    }
  }
}
