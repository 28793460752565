@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
.accordion-files {
  padding: 80px 0;
  &:last-child {
    border-bottom: 1px solid var(--gray-second);
  }
  @include desktopStyle {
    padding-top: get-vw(80px);
    padding-bottom: get-vw(80px);
  }
  @media only screen and (max-width: $bp-tablet) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  :global {
    .accordion_inner {
      padding-right: 0;
      display: flex;
      align-items: stretch;
      flex-wrap: wrap;
      gap: 16px;
      grid-gap: 16px;
      @include desktopStyle {
        gap: get-vw(16px);
        grid-gap: get-vw(16px);
      }
      @media only screen and (max-width: $bp-phone) {
        gap: 12px;
        grid-gap: 12px;
      }
    }
    .document-link {
      width: calc(50% - 8px);
      @include desktopStyle {
        width: calc(50% - get-vw(8px));
      }
      @media only screen and (max-width: $bp-phone) {
        width: 100%;
      }
    }
    .accordion {
      @media only screen and (max-width: $bp-tablet) {
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }

  &_wrapper {
    @media only screen and (max-width: $bp-tablet) {
      flex-direction: column;
    }
  }
  
  &_left {
    width: 32%;
    @media only screen and (max-width: $bp-tablet) {
      width: 100%;
      flex-direction: column-reverse;
      margin-bottom: 32px;
    }
  }

  &_right {
    width: 64%;
    max-width: 1010px;
    @include desktopStyle {
      max-width: get-vw(1010px);
    }
    @media only screen and (max-width: $bp-tablet) {
      width: 100%;
      max-width: none;
    }
  }

  &_image {
    margin-top: 60px;
    width: 100%;
    @include desktopStyle {
      margin-top: get-vw(60px);
    }
    @media only screen and (max-width: $bp-tablet) {
      margin-top: 0;
      margin-bottom: 32px;
      img {
        margin: 0 auto;
        max-width: 50vw;
      }
    }
    img {
      width: auto;
      height: auto;
      max-width: 100%;
      display: block;
    }
  }
}